<template>
  <div>
    <div v-if="basicInfo.orderCoder" style="border-bottom: 1px solid #ccc;display:flex;justify-content: space-between;cursor: pointer"> <span>{{ $t("title.orderinfo") }}</span> <span @click="showRowClick()">{{ showRow ? $t("page.hide") : $t("title.Show") }}</span></div>
    <div v-show="showRow">
      <!-- 订单信息 -->
      <div v-if="basicInfo.orderCoder">
        <el-row>
          <el-col>
            {{ $t("title.order") }}： {{ basicInfo.orderCoder }}
            <el-button
              v-if="basicInfo.orderCoder"
              type="text"
              @click="copyText(basicInfo.orderCoder, 0)"
            >{{ $t("title.copy") }}</el-button></el-col>
        </el-row>
        <el-form v-if="basicInfo.orderList && basicInfo.orderList[0]" ref="form" label-width="120px" class="orderInfo mb-3" label-position="left" style="border-bottom: 1px solid #ccc">
          <el-form-item :label="$t('title.orderamount')">
            <template>
              <span>{{ basicInfo.orderList[0].orderTotal }}</span>
            </template>
          </el-form-item>
          <el-form-item :label="$t('title.Paymenttime')">
            <template>
              <span v-if="basicInfo.orderList[0].paidTime">{{ basicInfo.orderList[0].paidTime | dataFormat }}</span>
            </template>
          </el-form-item>
          <el-form-item :label="$t('title.Customerids')">
            <template>
              <span>{{ basicInfo.messageSender }}</span>
            </template>
          </el-form-item>
          <el-form-item :label="$t('title.Platformmessage')">
            <template>
              <span />
            </template>
          <!-- Recipientaddress -->
          </el-form-item>
          <el-form-item :label="$t('title.Recipientaddress')">
            <template>
              <span>{{ basicInfo.orderList[0].buyerDest }}</span>
            </template>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- listing信息 -->
    <div v-if="basicInfo.listingId || basicInfo.orderCoder">
      <div style="border-bottom: 1px solid #ccc">{{ $t("title.Productinformation") }}</div>
      <p style="display:flex;justify-content: space-between;cursor: pointer"> <span>{{ $t("title.Product") }}</span> <span @click="showRowClick()">{{ showRow ? $t("page.hide") : $t("title.Show") }} </span></p>
      <div v-show="showRow">
        <div v-if="basicInfo.listingId && !basicInfo.orderList.length">
          Listing：<a :href="`https://www.amazon.com/gp/product/${basicInfo.listingId}`" target="_blank" style="color: #3296fa">https://www.amazon.com/gp/product/{{ basicInfo.listingId }}</a>
          <br>
        </div>
        <div v-for="(item, index) in basicInfo.orderList" :key="index">
          Listing:<a :href="`https://www.amazon.${item.sitesuffix}/gp/product/${item.listingId}`" target="_blank" style="color: #3296fa">https://www.amazon.{{ item.sitesuffix }}/gp/product/{{ item.listingId }}</a>
          <br>
          <el-form ref="form" label-width="100px" label-position="left" class="orderInfo mb-3">

            <el-form-item v-if="basicInfo.site === 'uk'" :label="$t('title.Producttitle')">
              <template>
                <span>{{ item.itemTitle }}</span>
              </template>
            </el-form-item>
            <el-form-item label="BU：">
              <template>
                <span>{{ item.bu }}</span>
              </template>
            </el-form-item>
            <el-form-item label="SKU：">
              <template>
                <span>{{ item.sku }}</span>
              </template>
            </el-form-item>
            <el-form-item :label="$t('title.Productrank')">
              <template>
                <span>{{ item.position }}</span>
              </template>
            </el-form-item>
            <el-form-item label="Style">
              <template>
                <span>{{ item.style }}</span>
              </template>
            </el-form-item>
            <el-form-item label="Color">
              <template>
                <span>{{ item.color }}</span>
              </template>
            </el-form-item>
            <el-form-item label="Size">
              <template>
                <span>{{ item.size }}</span>
              </template>
            </el-form-item>
            <el-form-item :label="$t('title.Quantity')">
              <template>
                <span>{{ item.number }}</span>
              </template>
            </el-form-item>
            <el-form-item :label="$t('title.FulfillmentMethod')">
              <template>
                <span>{{ item.shippingType }}</span>
              </template>
            </el-form-item>
            <el-form-item :label="$t('title.Trackingnumber')">
              <template>
                <span v-if="item.trackingNumber">
                  <a :href="`https://t.17track.net/zh-cn#nums=${item.trackingNumber}`" target="_blank" style="color: #3296fa">{{ item.trackingNumber }}</a>
                </span>
              </template>
            </el-form-item>

            <el-form-item :label="$t('title.Shippingtime')">
              <template>
                <span>{{ item.shippingTime?item.shippingTime:$t('title.Notshipped') }}</span>
              </template>
            </el-form-item>
            <el-form-item :label="$t('title.ProblemMentioned')">
              <el-row>
                <el-col :span="18">
                  <el-cascader
                    v-model=" item.messageMarkLabelList[0]"
                    :options="options"
                    :props="{ multiple: true, value: 'id', label: 'labelName', children: 'childNodes', }"
                    @change="getLabels"
                  />

                <!-- <el-cascader
                    v-else
                    clearable
                    :value="''"
                    :options="options"
                    :props="{ multiple: true, value: 'id', label: 'labelName', children: 'childNodes', }"
                    @change="getLabels"
                  /> -->
                </el-col>
                <el-col :span="6">
                  <el-button type="primary" class="ml-2" size="small" @click="tagSure(false, item.id)">{{ $t("title.sure") }}</el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <!-- 标签 -->
        </div>
      </div>
    </div>
    <!-- 单独标签 -->
    <el-form v-if="basicInfo.orderList && !basicInfo.orderList.length" label-width="100px" label-position="left" class="mt-2">
      <el-form-item :label="$t('title.customerID')">
        <template>
          <span>{{ basicInfo.messageSender }}</span>
        </template>
      </el-form-item>
      <el-form-item v-if="!basicInfo.orderList.length&&!basicInfo.orderCoder" :label="$t('title.MessageID')">
        <template>
          <span>{{ basicInfo.messageId }}</span>
        </template>
      </el-form-item>
      <el-form-item v-if="basicInfo.orderList" :label="$t('title.ProblemMentioned')">
        <el-row>
          <el-col :span="18">
            <el-cascader
              v-model="markLables"
              :options="options"
              :props="{ multiple: true, value: 'id', label: 'labelName', children: 'childNodes', }"
              @change="getLabels"
            />
          </el-col>
          <el-col :span="6">
            <el-button type="primary" class="ml-2" size="small" @click="tagSure(false, '')">{{ $t("title.sure") }}</el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <!-- 提醒 -->
    <!-- <el-form :inline="true" class="mt-2">
      <el-form-item label="提醒">
        <el-row>
          <el-col :span="22">
            <el-radio-group v-model="radio" size="small">
              <el-radio-button label="1">1天后</el-radio-button>
              <el-radio-button label="2">2天后</el-radio-button>
              <el-radio-button label="3">3天后</el-radio-button>
              <el-radio-button label="7">7天后</el-radio-button>
              <el-radio-button @click.native="definetimedialogVisible = true">自定义</el-radio-button>
            </el-radio-group>
          </el-col>
          <el-col :span="2">
            <el-button size="small" type="primary" style="margin-left: -8px" @click="changeDateSure()">确定</el-button>
          </el-col>
        </el-row>

        <el-row v-if="basicInfo.isWarn === 1">
          <el-tag closable @close="closeTag(basicInfo.id, 0, setDate(0))">{{
            basicInfo.warnTime
          }}</el-tag>
        </el-row>
      </el-form-item>
    </el-form> -->
    <div class="operateBox">
      <p class="operate">{{ $t('page.operate') }}</p>
      <!-- 备注消息 -->
      <el-form :inline="true" label-width="70px" class="demo-form-inline mt-2">
        <el-form-item :label="$t('title.note')">
          <el-input v-model="remark" :placeholder="$t('page.inputPlaceholder')" @blur="addNoticeSure()" />
        </el-form-item>
        <p>{{ $t('title.Note') }} : {{ newRemark }}</p>
        <!-- 流转工单 -->
        <el-form-item :label="$t('title.Transferto')">
          <el-select v-model="workFrom" clearable filterable value-key="id" @change="handleChange" @visible-change="_allBU">
            <el-option v-for="item in buOptions" :key="item.id" :label="item.userName" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="circulation">{{ $t("title.sure") }}</el-button>
        </el-form-item>
        <!-- <p>{{ newWorkFrom }}</p> -->
        <!-- 提醒 -->
        <p><el-form-item :label="$t('title.Remind')">
             <el-input v-model="radio" :placeholder="$t('page.inputPlaceholder')" @input="radioInput" @blur="changeDateSure()" />
           </el-form-item>
          <span>{{ $t('title.day') }}</span></p>
      </el-form>
      <!--  操作记录 -->
      <el-table :data="noteList" max-height="200" border class="mb-3 mt-1" :header-cell-style="{background:'#fafafa',height:'40px',padding:'0px'}" style="width: 100%">
        <!-- <el-table-column prop="remark" :label="$t('title.content')">
        <template slot-scope="scope">
          <el-input
            v-if="!flag"
            ref="input"
            v-model="scope.row.remark"
            style="text-align: left"
            placeholder="输入内容后，点击保存"
          />
          <span v-else>{{ scope.row.remark }}</span>
        </template>
      </el-table-column> -->
        <el-table-column prop="logContent" :label="$t('title.content')" />
        <el-table-column prop="userName" :label="$t('title.operator')" />
        <el-table-column prop="logTime" :label="$t('title.time')" />
      <!-- <el-table-column :label="$t('page.operate')" width="95">
        <template slot-scope="scope">
          <el-button v-if="!flag" type="text" @click="saveNote(scope.row)">{{
            $t("title.Save")
          }}</el-button>
          <el-button v-if="flag" type="text" @click="modifyNote()">{{
            $t("title.Edit")
          }}</el-button>
          <el-button type="text" @click="deleteNote()">{{
            $t("page.delete")
          }}</el-button>
        </template>
      </el-table-column> -->
      </el-table>
    </div>
    <!-- 分类 -->
    <!-- <div>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item :label="$t('title.classify')">
          <el-select
            v-model="type"
            clearable
            :placeholder="$t('page.selectPlaceholder')"
            @change="_gettemplatelistbywhere"
          >
            <el-option :label="$t('title.OrderAll')" value="" />
            <el-option v-for="item in typeData" :key="item.id" :label="item.typeName" :value="item.typeName" />
          </el-select>
        </el-form-item>
      </el-form>
      <div
        :style="{
          height: !basicInfo.orderList.length ? '250px' : '220px',
          overflow: 'auto',
        }"
      >
        <el-row v-for="(item, index) in tableData" :key="index" type="flex" justify="space-between" class="border">
          <el-col :span="14" style="padding: 10px 0">
            <el-tooltip class="item" effect="dark" :content="item.content" placement="top-start">
              <span>{{ item.title }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="6" :offset="6">
            <el-button type="text" @click="copyText(item.content, 1)">{{
              $t("title.copy")
            }}</el-button>
          </el-col>
        </el-row>
      </div>
    </div> -->
    <!-- 自定义提醒时间设置 -->
    <!-- <el-dialog
      title="自定义日期"
      :visible.sync="definetimedialogVisible"
      width="40%"
      :append-to-body="true"
    >
      <el-date-picker
        v-model="value1"
        value-format="yyyy-MM-dd HH:mm:ss"
        type="datetime"
        placeholder="选择日期时间"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="definetimedialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="definetimeSure">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import mixin from '@/mixin/oms-mixin'
import { getLogByMessageId, allBU, messagesGetLabel, deletemailremark, updatemailremark, batchMarkLabelInsert, batchUpdateMessageWarnById, insertCirculation } from '@/api/service-management'
// import { validURL } from '@/utils/validate'
// import { deepClone } from '@/utils'
// import store from '@/store'
export default {
  name: 'OrderInfo',
  mixins: [mixin],
  props: {
    dialogVisible: {
      type: Boolean
    },
    orderInfo: {
      type: Array,
      default: () => ([])
    },
    basicInfo: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    },
    status: {
      type: Number,
      default: 0
    },
    noteLists: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      label: [],
      value1: '',
      warnTime: '',
      definetimedialogVisible: false,
      arr: [],
      tagForm: {
        tags: []
      },
      radio: '',
      options: [],
      flag: true,
      remark: '',
      tableData: [],
      typeData: [],
      type: '',
      threadId: '',
      expandedKeys: [],
      showRow: true, // 切换显示input
      workFrom: '',
      buOptions: [],
      beUserId: '',
      beUserName: '',
      noteList: [],
      newRemark: '',
      newWorkFrom: '',
      markLables: [],
      tiemMarkLables: []
    }
  },
  watch: {
    'dialogVisible'(val) {
      console.log(val)
      // if (!val) {
      //   this.radio = ''
      //   this.remark = ''
      // } else {
      //   this.orderInfo.forEach((v, i) => {
      //     console.log(v)
      //   })
      //   this.$nextTick(() => {
      //     if (this.basicInfo) {
      //       this.markLables = this.basicInfo.messageMarkLabelList && this.basicInfo.messageMarkLabelList.length > 0 ? JSON.parse(JSON.stringify(this.basicInfo.messageMarkLabelList[0].markLabel)) : []
      //     }
      //     // if (this.orderInfo) {
      //     // this.orderInfo.messageMarkLabelList.forEach(e => {
      //     //   console.log(e)
      //     // })
      //     // this.orderInfo.messageMarkLabelList.forEach((v, i) => console.log(v))
      //     // }
      //     this._messagesGetLabel()
      //     // this.getType()
      //     // this._gettemplatelistbywhere()
      //     this._allBU()
      //     this._getLogByMessageId({ messageId: this.basicInfo.id })
      //   })
      // }
    },
    'basicInfo'(val) {
      if (val) {
        this.markLables = val.messageMarkLabelList && val.messageMarkLabelList.length > 0 ? JSON.parse(JSON.stringify(val.messageMarkLabelList[0].markLabel)) : []

        if (val.orderList) {
          val.orderList.forEach((v, i) => {
            v.index = i
            v.messageMarkLabelList = [v.messageMarkLabelList && v.messageMarkLabelList.length > 0 ? JSON.parse(JSON.stringify(v.messageMarkLabelList[0].markLabel)) : []]
            console.log(v.messageMarkLabelList)
          })
          this.basicInfo.orderList = val.orderList
          console.log(this.basicInfo.orderList)
        }
        if (val.site) {
          val.orderList.map(e => {
            if (val.site === 'com') {
              e.sitesuffix = 'com'
            } else if (val.site === 'ca') {
              e.sitesuffix = 'ca'
            } else if (val.site === 'mx') {
              e.sitesuffix = 'com.mx'
            } else if (val.site === 'au') {
              e.sitesuffix = 'com.au'
            } else if (val.site === 'uk') {
              e.sitesuffix = 'co.uk'
            } else if (val.site === 'de') {
              e.sitesuffix = 'de'
            } else if (val.site === 'fr') {
              e.sitesuffix = 'fr'
            } else if (val.site === 'es') {
              e.sitesuffix = 'es'
            } else if (val.site === 'it') {
              e.sitesuffix = 'it'
            } else if (val.site === 'nl') {
              e.sitesuffix = 'nl'
            } else if (val.site === 'se') {
              e.sitesuffix = 'se'
            }
          })
          console.log(val.orderList)
          this.$forceUpdate()
        }
        // this.$nextTick(() => {
        this._messagesGetLabel()
        this._allBU()
        console.log(this.basicInfo.id)
        this._getLogByMessageId({ messageId: this.basicInfo.id })
        // })
      }
    },
    // 'orderInfo'(val) {
    //   console.log(val)
    //   val.map(e => {
    //     e.messageMarkLabelList = e.messageMarkLabelList && e.messageMarkLabelList.length > 0 ? JSON.parse(JSON.stringify(e.messageMarkLabelList[0].markLabel)) : []
    //   })
    // },
    'noteLists'(val) {
      if (val) {
        this.noteList = val || []
        this.newRemark = ''
        const typeText = '备注'
        const arr = []
        console.log(val)
        val.map(e => {
          if (e.logType === typeText) arr.push(e)
        })
        const maxObj = this.getMaxArr(arr, typeText)
        if (maxObj) {
          typeText === '备注' ? this.newRemark = maxObj.logContent : ''
        }
      }
    },
    deep: true
  },
  created() { },
  mounted() {
    this._messagesGetLabel()
    this.orderInfo.forEach((v, i) => {
      console.log(v)
    })
    this._allBU()
    console.log(this.basicInfo.id)
    // this._getLogByMessageId({ messageId: this.basicInfo.id })
  },
  methods: {
    // 获取所有bu
    async _allBU() {
      const { datas } = await allBU()
      this.buOptions = datas
    },
    // 获取操作记录
    async _getLogByMessageId(id, type) {
      const { datas } = await getLogByMessageId(id)
      this.noteList = datas
      const typeText = '备注'
      const arr = []
      this.noteList.map(e => {
        if (e.logType === typeText) arr.push(e)
      })
      const maxObj = this.getMaxArr(arr, typeText)
      if (maxObj) {
        typeText === '备注' ? this.newRemark = maxObj.logContent : ''
      } else {
        this.newRemark = ''
        this.newWorkFrom = ''
      }
    },
    getMaxArr(arr) {
      // 最大的值
      const maxValue = Math.max.apply(Math, arr.map((item, index) => {
        return item.id
      }))
      // 判断是否有值
      if (isNaN(maxValue)) return null
      // 最大的对象
      for (var keys in arr) {
        if (arr[keys].id === maxValue) {
          return arr[keys]
        }
      }
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    async deleteNote() {
      const { status, msg } = await deletemailremark(this.basicInfo.id)
      status === 200 & this.$message.success(msg)
      this.$emit('getNote')
    },
    async tagSure(bool, id) {
      let obj = {}
      const arr = []
      const flag = this.arr.find(item => item.length === 0)
      if (this.arr.length && !flag) {
        for (var i = 0; i < this.arr.length; i++) {
          for (var j = 0; j < this.arr[i].length; j++) {
            obj = Object.assign({}, { orderId: id, labelId: this.arr[i][j], messageId: this.basicInfo.id })
            arr.push(obj)
          }
        }
      } else {
        obj = Object.assign({}, { orderId: id, labelId: [], messageId: this.basicInfo.id })
        arr.push(obj)
      }
      console.log(arr)
      const { code, msg } = await batchMarkLabelInsert(bool, arr)
      code === 0 && this.$message.success(msg)
    },
    // 获取所有标签
    async _messagesGetLabel() {
      const { datas } = await messagesGetLabel()
      this.options = datas
    },
    setName(datas) { // 遍历树  获取id数组
      for (var i = 0; i < datas.length; i++) {
        // console.log(datas[i]);
        this.expandedKeys.push(datas[i].id)
        if (datas[i].childNodes) {
          this.setName(datas[i].childNodes)
        } else {
          this.tagForm.tags.push(this.expandedKeys)
        }
        this.expandedKeys = []
      }
      console.log(this.tagForm.tags)
      /*   this.tagForm.tags.push(this.expandedKeys)
        this.expandedKeys=[]  */
    },
    // modifyNote() {
    //   this.flag = false
    //   // this.$refs.input.focus()
    // },
    // saveNote(row) {
    //   const { remark } = row
    //   if (remark.trim()) this.addNoticeSure(this.basicInfo.id, remark, this.$store.getters.name)
    //   this.$forceUpdate()
    //   this.$emit('getNote')
    // },
    // async getType() {
    //   const { data } = await gettemplatetypelistbywhere({
    //     platform: 'Ebay'
    //   })
    //   this.typeData = data
    // },
    copyText(value, FLAG) {
      var input = document.createElement('input')
      input.value = value
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.$message.success(this.$t('tips.Copied'))
      FLAG === 1 && this.$emit('getfocus', value)
    },
    getLabels(val) {
      console.log(val)
      this.arr = []
      this.arr.push(val)
      this.arr.filter(item => item.length !== 0)
      console.log(this.arr)
    },
    handleChange(val) {
      console.log(val)
      this.beUserId = val.userId
      this.beUserName = val.userName
    },
    // 流转工单
    async circulation() {
      this.parmas = { messageId: this.basicInfo.id, workFrom: this.$store.getters.name, workFromId: this.$store.getters.userId, workNumber: this.basicInfo.workNumber, workTo: this.beUserName, workToId: this.beUserId }
      console.log(this.parmas)
      // Number(this.radio) === 1 ? this.warnTime = this.setDate(1) : Number(this.radio) === 2 ? this.warnTime = this.setDate(2) : Number(this.radio) === 3 ? this.warnTime = this.setDate(3) : Number(this.radio) === 7 ? this.warnTime = this.setDate(7) : ''
      const { code, msg } = await insertCirculation(this.parmas)
      code === 0 && this.$message.success(msg)
      if (code === 0) {
        this.workFrom = ''
        const type = 2
        this._getLogByMessageId({ messageId: this.basicInfo.id }, type)
      }
    },
    // 设置提醒时间
    async changeDateSure() {
      if (this.radio === '') {
        return
      }
      if (Number(this.radio) > 30) {
        this.$message.error(this.$t('tips.LesserThan'))
        return
      } // Number(this.radio) === 1 ? this.warnTime = this.setDate(1) : Number(this.radio) === 2 ? this.warnTime = this.setDate(2) : Number(this.radio) === 3 ? this.warnTime = this.setDate(3) : Number(this.radio) === 7 ? this.warnTime = this.setDate(7) : ''
      this.warnTime = this.setDate(Number(this.radio))
      const { code, msg } = await batchUpdateMessageWarnById(this.basicInfo.id, 1, this.warnTime)
      code === 0 && this.$message.success(msg)
      if (code === 0) {
        this.radio = ''
        this._getLogByMessageId({ messageId: this.basicInfo.id })
      }
    },
    setDate(n) {
      var day3 = new Date()
      var day4 = new Date()
      // console.log(day4.getHours());
      day3.setTime(day3.getTime() + n * 24 * 60 * 60 * 1000)
      var s3 = day3.getFullYear() + '-' + (day3.getMonth() + 1) + '-' + day3.getDate() + ' ' + day4.getHours() + ':' + day4.getMinutes() + ':' + day4.getSeconds()
      console.log(s3)
      return s3
    },
    async closeTag(id, status, time) {
      const { code, msg } = await batchUpdateMessageWarnById(id, status, time)
      code === 0 && this.$message.success(msg)
      this.basicInfo.isWarn = 0
    },
    async definetimeSure() {
      const { code, msg } = await batchUpdateMessageWarnById(this.basicInfo.id, 1, this.value1)
      code === 0 && this.$message.success(msg)
      this.$set(this.basicInfo, 'isWarn', 1)
      this.$set(this.basicInfo, 'warnTime', this.value1)
      this.definetimedialogVisible = false
      this.value1 = ''
    },
    // 备注信息
    async addNoticeSure() {
      if (this.remark === '') {
        this.$message.error(this.$t('tips.notEmpty'))
        return
      }
      this.parmas = { messageId: this.basicInfo.id, remark: this.remark, operatorName: this.$store.getters.name }
      const { code, msg } = await updatemailremark(this.parmas)
      code === 0 && this.$message.success(msg)
      if (code === 0) {
        this.remark = ''
        const type = 1
        this._getLogByMessageId({ messageId: this.basicInfo.id }, type)
      }
      // this.flag = true
      // this.$forceUpdate()
      // this.$emit('getNote')
    },
    radioInput(e) {
      const RegExp = /^[1-9]\d*$/
      if (!RegExp.test(e)) {
        this.radio = ''
      }
      if (parseInt(e) > 30) {
        this.radio = ''
        this.$message.error(this.$t('tips.GreaterThan30'))
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-form-item {
  margin-bottom: 0px !important;
}
/deep/.el-form-item--medium {
  .el-form-item__content,
  .el-form-item__label {
    line-height: 28px !important;
  }
}
  .operateBox{
    margin: 10px 0;
    padding: 0 10px;
    border: 1px solid #e6e6e6;
  }
  .operate{
    font-weight: 600px;
    font-size: 16px;
    border-bottom: 1px solid #999;
  }
</style>
